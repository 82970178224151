.top-banner{ 
    background:url(../../assets/images/top-banner-bg.jpg) no-repeat left top; 
    background-size:cover; 
    position: relative;
    .top-banner_content{
        flex: 0.45;
        padding-left: 4%;
        .sub-head{
            color:#fff; 
            margin-bottom:10px;
        }
        .head{
            font-weight:700; 
            color:#fff; 
            margin-bottom:25px;
        }
        .text{
            font-size:18px; 
            color:#fff; 
            margin-bottom:50px;
        }
    }
    &__web{
        padding:160px 20px 0 20px; 
        .sub-head{
            font-size:18px; 
        }
        .head{
            font-size:42px; 
            line-height: 46px;
        }
    }
    &__mob{
        padding:2rem 1rem; 
        .sub-head{
            font-size:15px; 
        }
        .head{
            font-size:28px; 
            line-height: 32px;
        }
    }
    .app-image{
       flex: 0.45;
    }
    .elips-shapes{
        background:url(../../assets/images/elips-shapes.svg) no-repeat left top; 
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
        height: 40px;
    }
}

.support-by{
    // border: 1px solid #000000;
    // background-color: white;
    // position: relative;
    // border-radius: 50% 50% 0 0;
    // top:-5rem;
    padding: 32px 50px;
    text-align: center;
    .react-multi-carousel-list {
        padding: 2rem 0;
        .react-multi-carousel-track{
            align-items: center;
            width: 100%;
        }
        .custom-dot{
            width: 8px;
            height: 8px;
            border-radius: 30px;
            background: rgba(0, 0, 0, 0.5);
            border: none;
            margin: 5px 7px;
            padding: 0
        }
        .custom-dot.active {
            background:#000000; 
            width:12px; 
            height:12px;
        }
    }
}

.key-advantages{
    background: rgba(246, 246, 246, 1);
    .head{
        text-align: center;
        font-weight: 500;
    }
    .sub-head{
        font-weight: 700;
        text-align: center;
    }
    &__web{
        padding: 4rem;
        .head{
            font-size: 18px;
        }
        .sub-head{
            font-size: 40px;
        }
        .key-adv-wrapper{
            display: flex;
            .key-adv-box{
                width:25%;
                padding: 1.5rem;
                .key-adv-title{
                    font-size: 1.5rem;
                    font-weight: 900;
                    line-height: 1.5rem;
                    padding-right: 2.75rem;
                    margin-bottom: 1.5rem
                }
            }
        }
    }
    &__mob{
        padding: 2rem 1.25rem;
        .head{
            font-size: 18px;
            margin-bottom: 10px;
        }
        .sub-head{
            font-size: 1.75rem;
            line-height: 2rem;
        }
        .key-adv-wrapper{
            .key-adv-box{
                text-align: center;
                padding-bottom: 2rem;
                .key-adv-title{
                    font-size: 1.5rem;
                    font-weight: 900;
                    line-height: 1.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

.payment-methods{
    &__web{
        padding: 64px;
        .head{
            padding: 65px 275px 0px 0px;
            font-size: 2.5rem;
            font-weight: 700
        }
        .talk-to-us-cta{
            width: 172px
        }
    }
    &__mob{
        padding: 2rem 0.7rem;
        text-align: center;
        .head{
            font-size: 1.75rem;
            line-height: 2rem;
            font-weight: 700;
        }
        .talk-to-us-cta{
            width: 100%;
            margin-bottom: 2.5rem
        }
    }

}

.all-transaction{
    &__web{
        padding: 0px 0px 64px 80px;
        .head{
            font-size: 2.5rem;
        }
        .get-the-app-cta{
            width: 172px;
            margin-top: 3rem
        }
    }
    &__mob{
        text-align: center;
        padding: 2rem 0.75rem;
        .head{
            font-size: 1.75rem;
            line-height: 2rem;
        }
        .get-the-app-cta{
            width: 100%;
            margin-bottom: 2.5rem
        }
    }
}

.testimonal{
    background: url(../../assets/images/testimonial-bg.svg);
    color: #ffffff;
    .carousel-container{
        direction: ltr;
        width: 100%;
    }
    .react-multi-carousel-list {
        padding: 0rem 0 2rem 0;
        .custom-dot{
            width: 8px;
            height: 8px;
            border-radius: 30px;
            background: rgba(255, 255, 255, 0.5);
            border: none;
            margin: 5px 7px;
            padding: 0
        }
        .custom-dot.active {
            background:#fff; 
            width:12px; 
            height:12px;
        }
    }
    .carousel-item{
        margin:10px;
        .carousel-item-paper{
            border-radius:10px; 
            padding:18px;
            height:280px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__web{
        padding: 50px;
        .sub-head{
            font-size: 2.5rem;
            font-weight: 700;
        }
    }
    &__mob{
        padding: 2rem 0.7rem;
        .head{
            text-align: center;
            margin-bottom: 10px;
        }
        .sub-head{
            font-size: 1.75rem;
            line-height: 2rem;
            font-weight: 700;
            padding: 0 3rem;
            text-align: center;
            margin-bottom: 2rem
        }
    }
}

.faq-wrapper{
    position: relative;
    .head{
        font-weight: 700;
    }
    &__web{
        margin: 72px 0px;
        .faq:before{
            width:239px; 
            height:272px; 
            background:url(../../assets/images/faq-left-bg.svg) no-repeat left top; 
            content:''; 
            position:absolute; 
            left:0px; 
            bottom:0px;
        }
        .faq:after{
            width:239px; 
            height:272px; 
            background:url(../../assets/images/faq-right-bg.svg) no-repeat left top; 
            content:''; 
            position:absolute; 
            right:0px; 
            top:0;
        }
    }
    &__mob{
        padding: 2rem 0.75rem;
        .head{
            font-size: 1.75rem;
            line-height: 2rem;
        }
        .sub-head{
            font-size: 18px;
            margin-bottom: 10px;
        }

    }
}

.download-app-wrapper{
    .download-app{
        color:#fff; 
        .cta{
            margin-bottom:30px; 
            font-size:16px; 
            font-weight:500; 
        }
    }
    &__web{
        max-height: 380px;
        background: linear-gradient(162.44deg, #207028 0%, #0B360F 100%);
        margin:60px 72px;
        padding-left: 40px;
        border-radius:15px; 
        .download-app{
            padding-top: 20px;
            .head,.head1{
                font-weight: 700; 
                font-size: 2rem;
                line-height: 2.5rem; 
            }
            .sub-head{
                font-weight: 300; 
                line-height: 22px; 
                color:#fff; 
                margin-top: 15px;
                margin-bottom:15px;
            }
            .cta1{
                width: 245px
            }
            .cta2{
                width: 144px
            }
            .app-action1{
                padding-right: 10px;
            }
        }
    }
    &__mob{
        text-align: center;
        background: linear-gradient(90deg, #5B8828 0%, #6F9F39 52.08%, #5A8B24 100%);
        padding: 24px;
        position: relative;
        .download-app{
            background: linear-gradient(162.44deg, #207028 0%, #0B360F 100%);
            padding: 32px 16px;
            border-radius:15px; 
            position: relative;
            z-index: 9;
            .head{
                font-size: 1.75rem;
                line-height: 2rem;
            }
            .head1,.head2{
                display: none;
            }
            .sub-head{
                font-size: 14px;
                margin-bottom: 10px;
            }
            .cta1{
                margin-bottom: 15px;
            }
            .app-action1,.app-action2{
                width:60%
            }
        }
    }
    .wrapper-logo{
        img{
            border-radius:15px; 
        }
    }
}
.download-app-wrapper__mob:before{
    content: '';
    position: absolute;
    background: url(../../assets/images/m-download-top.svg) no-repeat left top;
    width: 300px;
    height: 100%;
    top:0;
    left:0
}
.download-app-wrapper__mob:after{
    content: '';
    position: absolute;
    background: url(../../assets/images/m-download-bottom.svg) no-repeat right bottom;
    width: 100%;
    height: 100%;
    bottom: 0;
    right:0
}

.social{
    a{
        margin-right:10px
    }
}

.content{
    .banner{
        &__mob{
            .title{
                font-size: 2rem;
            }
        }
    }
}
.body{
    &__mob{
        .ques-ans__container{
            gap:1rem;
            .ques{
                font-size: 1.5rem;
            }
            .ans{
                font-size: 1rem;
            }
            .ques-ans__container{
                .each-ques-ans{
                    gap:1rem;
                }
                .ques{
                    font-size: 1.25rem;
                }
            }
        }
    };
}

.support{
    .faq-wrapper{
        &__web{
            .faq:before{
                background: none;
            }
            .faq:after{
                background: none;
            }
        }
    }
}

.contact-form{
    background-color: #F6F6F6;
    box-shadow: 0px 1px 6px 0px #0000001F;
    text-align: center;
    height: 100%;
    border-radius: 12px;
}

.body{
    &__mob{
        .acc-wrapper{
            gap:0
        }
        .acc{
            display: none;
        }
    }
}