.content{
    background: linear-gradient(162.44deg, #207028 0%, #0B360F 100%); 
    color: #fff;
    .header{
        box-shadow: none;
    }
}
.header{
    background: linear-gradient(162.44deg, #207028 0%, #0B360F 100%); 
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12); 
    color: #fff;
    .nav-link{
        padding: 10px 20px;
        margin-right: 25px;
    }
    &__web{
        padding:0.75rem 3rem; 
        .cta{
            width:148px
        }

    }
    .menu-how-it-works{
        padding: 20px;
        margin-top: 24px;
    }
    &__mob{
        .contents{
            padding:1.25rem; 
        }
        .cta-wrapper{
            position: fixed;
            bottom:0;
            background: #fff;
            z-index:19;
            width:100%;
            border-top-left-radius:20px;
            border-top-right-radius:20px;
            .cta-inner-div{
                padding: 15px 20px;
                width:90%
            }
        }
    }
}
.mob-drawer{
    .MuiDrawer-paperAnchorLeft{
        width: 100%;
    }
    .about-us-text{
        padding-left: 10px;
    }
}

.ans{
    white-space: pre-line;
    font-size: 18px;
    color: #454545;
    line-height: 1.75rem
}

.more-data-wrapper{
    background-color: #F4F9EC;
}