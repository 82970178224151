html, body {
    height: 100%; 
    margin:0px; 
    padding:0px;
    /* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none; /* IE and Edge */
  
  /* Hide scrollbar for Firefox */
  scrollbar-width: none; /* Firefox */
  
}
body{
    background:#fff; 
    font-family: 'Roboto', sans-serif; 
    font-size:16px; 
    color:#32373c
}
.d-flex{
    display: flex;
}
.justify-content-space-between{
    justify-content: space-between;
}
.justify-content-end{
    justify-content: end;
}
.font-weight-600{
    font-weight: 600!important;
}
.p-l-45{
    padding-left: 45px!important
}
.m-t-15{
    margin-top: 15px!important
}
.m-t-25{
    margin-top: 25px!important
}
.m-b-25{
    margin-bottom: 25px!important
}
.align-content-center{
    align-content: center;
}
.cursor-pointer{
    cursor: pointer
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    // -webkit-box-shadow: 0 0 0px 1000px #1D2939 inset;
}

