.footer {
    position:relative; 
    background: linear-gradient(162.06deg, #131313 0%, #2A2A2A 51.19%, #0C0C0C 98.29%);
    color:#fff; 
    .f-header {
        border-bottom:1px solid rgba(255, 255, 255, 0.5); 
        padding-bottom:35px;
        margin-bottom:35px;
        .f-text {
            font-size:16px; 
            font-weight:400;
            line-height:22.4px;
        }
    }
    .social {
        padding:20px 0px; 
        text-align:right;
        a{
            float:right; 
            margin-left:10px; 
            svg{
                width:100%; 
                height:auto;
            }
        }
    }
    &__web{
        padding:56px 63px 72px 63px;
        .f-logo {
            width:181px;
        }
        .links {
            width:100%; 
            display:table; 
            border-bottom:1px solid rgba(255, 255, 255, 0.5); 
            padding-bottom:35px;
            .newsletter{
                float:left; 
                width:30%;
                .head{
                    font-size: 20px; 
                    font-weight: 600; 
                    line-height: 22px; 
                    margin-bottom:35px;
                }
                .text{
                    font-size: 18px; 
                    font-weight: 300; 
                    line-height: 25px;  
                    margin-bottom:20px;
                }
            } 
            .subscribe{
                display: flex;
                &__email{
                    flex:0.75
                }
                &__button{
                    flex:0.2;
                    margin-left:10px
                }
            }
            .link-box{
                float:left; 
                width:17.5%;
                .head {
                    font-size: 20px; 
                    font-weight: 600; 
                    line-height: 22px; 
                    margin-bottom:35px;
                }
                a {
                    font-size: 18px; 
                    font-weight: 400; 
                    line-height: 25.2px; 
                    display:table; 
                    text-decoration:none; 
                    margin-bottom:15px;
                    color:#fff; 
                }
            }
        }
    }
    &__mob{
        text-align: center;
        padding: 32px 24px 70px 24px;
        .f-logo {
            width:100%;
            margin-bottom: 32px;
        }
        .links{
            display: flex;
            flex-direction: column-reverse;
        }
        .newsletter{
            .head{
                font-size: 20px; 
                font-weight: 600; 
                line-height: 22px; 
                margin-bottom:20px;
                color: #fff;
            }
            .text{
                font-size: 18px; 
                font-weight: 300; 
                line-height: 25px;  
                margin-bottom:20px;
            }
        } 
        .subscribe{
            &__email, &__button{
               width:100%;
               margin-bottom: 20px;
            }
        }
        .link-box{
            border: none;
            box-shadow: none;
            margin: 0;
            border-bottom: 1px solid #D1D1D1;
            border-radius: 0;
            color: #fff;
            background: transparent;
            .head {
                font-size: 20px; 
                font-weight: 600; 
                line-height: 22px; 
                color: #fff;
            }
            .expand-icon{
                color: #fff;
            }
            a {
                font-size: 18px; 
                font-weight: 400; 
                line-height: 25.2px; 
                display:table; 
                text-decoration:none; 
                margin-bottom:15px;
                color: #fff;
            }
            &.Mui-expanded{
                margin: 0
            }

        }
    }
}

