.sideBar {
  .MuiDrawer-paper {
    padding: 0.5rem;
    background: transparent;
    border: none;
    color: #fff;
    .header {
      box-shadow: none;
    }
  }
}

.navBarDash_mob {
  &.MuiAppBar-root {
    padding: 0.5rem;
    background: linear-gradient(162.44deg, #207028 0%, #0b360f 100%);
    color: #fff;
    .header {
      box-shadow: none;
    }
  }

  .MuiFormControl-root {
    width: 90%;
    border: 1px solid white;
    border-radius: 5px;
    background: rgba(256, 256, 256, 0.1);
    .MuiSvgIcon-root {
      color: #fff;
    }
    .MuiFocused {
      color: #fff;
    }
    .MuiInputLabel-root {
      &.Mui-focused {
        color: white;
      }
    }
    .MuiInputBase-root {
      &::after {
        border: none;
      }
    }
  }
}
.drawer-content {
  height: 100%;
  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .drawer-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiDivider-root {
    border-color: rgba(256, 256, 256, 0.25);
  }
  .MuiListItem-root {
    padding: 0.1rem 0.5rem;
  }
  .MuiListItemIcon-root {
    margin-right: -1.8rem;
  }
}

.nav-item {
  &.Mui-selected {
    background-color: rgba(256, 256, 256, 0.15) !important;
    opacity: 0.8;
  }
}
// .nav-item:nth-child(6),
// .nav-item:nth-child(9) {
//   border-bottom: 1px solid white;
//   padding-bottom: 1rem;
//   margin-bottom: 1rem;
// }
