.sign-in-pic{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.share-app{
    text-align: center;
    &__web{
        .share-app__email-div{
            display: flex;
            justify-content: center;
            &__email{
                flex: 0.45
            }
            &__button{
                flex: 0.11
            }
        }
    }
    &__mob{
        .cross-icon{
            padding-top: 0.75rem;
            padding-right: 1rem;
        }
        .share-app__email-div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem;
        }
    }
}
.get-app{
    &__web{
        .main-wrapper{
            display:flex;
            justify-content: center;
            .cross-icon{
                margin-top: -2rem;
                align-self: end;
            }
            .no-wrapper{
                display: flex;
                justify-content: center;
            }
        }
    }
    &__mob{
        .main-wrapper{
            display:flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem;
            text-align: center;
            .app-actions{
                img{
                    width:30%
                }
            }
        }
    }
}